<template>
  <el-form-item prop="">
    <th-input-title
      :info="
        $t('pages.payment_options.edit.form.properties.card_circuits.tooltip')
      "
      :title="
        $t('pages.payment_options.edit.form.properties.card_circuits.label')
      "
    />
    <div class="flex items-center">
      <el-switch
        class="mr-2"
        data-testid="card-circuit"
        :value="!!localValue"
        @change="initCardCircuitsValue"
      />

      <el-select
        id="cardCircuits"
        v-model="localValue"
        class="w-full"
        :disabled="!localValue"
      >
        <el-option
          v-for="type in cardCircuitsTypes"
          :key="type.value"
          :label="type.label"
          :value="type.value"
        />
      </el-select>
    </div>
  </el-form-item>
</template>

<script>
import compare from 'just-compare'
import { getCardCircuitsTypes } from '../helpers'

export default {
  props: {
    modelValue: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      cardCircuitsTypes: getCardCircuitsTypes(this)
    }
  },
  computed: {
    localValue: {
      get() {
        return this.getSelectedCardCircuit({ list: this.modelValue })?.value
      },
      set(value) {
        this.$emit(
          'update:modelValue',
          this.getSelectedCardCircuit({ name: value })?.matchers
        )
      }
    }
  },

  methods: {
    getSelectedCardCircuit({ list, name }) {
      return (
        this.cardCircuitsTypes.find(
          ({ matchers, value }) => compare(matchers, list) || name === value
        ) || null
      )
    },
    initCardCircuitsValue(isActive) {
      this.localValue = isActive ? this.cardCircuitsTypes[0].value : null
    }
  }
}
</script>
