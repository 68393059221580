<template>
  <th-page-wrapper
    class="payment-edit"
    color="var(--secondary-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <resource-form ref="form" @new-item="handleNavigationAfterCreation" />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { usePaymentOptionsStore } from '@/store/paymentOptions'
import th from '@tillhub/javascript-sdk'
import ResourceForm from './components/form'

export default {
  name: 'PaymentEdit',
  components: {
    ResourceForm
  },
  metaInfo() {
    return {
      title: this.$t('common.resource.payment_option.singular')
    }
  },
  setup() {
    const paymentOptionsStore = usePaymentOptionsStore()
    return { paymentOptionsStore }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      return [null, undefined, 'new'].includes(this.$route.params.id)
    },
    actions() {
      return {
        permissionPrefix: 'accounting:payment_options',
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleNavigationAfterCreation() {
      if (this.navigationAfterCreation === 'edit') {
        this.goToEdit()
      } else {
        this.goBack()
      }
    },
    goBack() {
      this.$router.push({ name: 'accounting-payment-options' })
    },
    goToEdit() {
      this.$refs.form?.payload?.id
        ? this.$router.push({
            name: 'payment-options-edit',
            params: { id: this.$refs.form.payload.id }
          })
        : this.goBack()
    },
    async handleDelete() {
      const payload = this.$refs.form.payload
      const confirm = await this.$askToDelete(
        payload.name || payload.account || payload.id
      )
      if (confirm) this.deleteAccount(payload)
    },
    async deleteAccount(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.payment_option.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.payment_option.singular')
      })

      try {
        const inst = th.paymentOptions()
        await inst.delete(payload.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        // Clean indexes list, to be recalculated
        this.paymentOptionsStore.setIndexesList([])
        this.goBack()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped></style>
