<template>
  <th-wrapper :title="$t('common.titles.general_info.title')" class="m-8">
    <!-- Center actions -->
    <template #center>
      <el-checkbox v-model="form.active" :disabled="isCardCircuitsEnabled">
        {{ $t('pages.payment_options.edit.form.properties.active.label') }}
      </el-checkbox>
    </template>
    <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="$t('pages.payment_options.edit.form.properties.name.label')"
            prop="name"
          >
            <el-input id="name" v-model="form.name" />
          </el-form-item>
        </el-col>

        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="$t('pages.payment_options.edit.form.properties.type.label')"
            prop="type"
          >
            <div class="flex flex-row items-center">
              <el-select
                id="type"
                v-model="form.type"
                :placeholder="
                  $t(
                    'pages.payment_options.edit.form.properties.type.placeholder'
                  )
                "
                class="w-full"
              >
                <el-option
                  v-for="item in paymentOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <th-popover
                v-if="currentDeprecatedType"
                :text="
                  $t('pages.payment_options.edit.form.properties.type.tooltip')
                "
                placement="bottom"
                class="pl-2"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="
              $t('pages.payment_options.edit.form.properties.order_index.label')
            "
            prop="order_index"
            required
          >
            <el-input-number
              id="order_index"
              v-model="form.order_index"
              :min="0"
              :max="100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.payment_options.edit.form.properties.fa_account_number.label'
              )
            "
            prop="fa_account_number"
          >
            <el-input id="fa_account_number" v-model="form.fa_account_number" />
          </el-form-item>
        </el-col>

        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.payment_options.edit.form.properties.discrepancy_account.label'
              )
            "
            prop="discrepancy_account"
          >
            <el-input v-model="form.discrepancy_account" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="24">
          <el-form-item
            v-if="showCurrencySelector"
            :label="
              $t('pages.payment_options.edit.form.properties.currency.label')
            "
            prop="currency"
          >
            <th-currency-select
              id="currency"
              v-model="form.currency"
              :allowed-currencies="currencies"
              :placeholder="
                $t(
                  'pages.payment_options.edit.form.properties.currency.placeholder'
                )
              "
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.payment_options.edit.form.properties.price_range.amount.min.label'
              )
            "
            prop="price_range.min_amount"
          >
            <th-currency-input
              id="price_range.min_amount"
              v-model="form.price_range.min_amount"
              clearable-is-left
              :currency="form.currency"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.payment_options.edit.form.properties.price_range.amount.max.label'
              )
            "
            prop="price_range.max_amount"
          >
            <th-currency-input
              id="price_range.max_amount"
              v-model="form.price_range.max_amount"
              clearable-is-left
              :currency="form.currency"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- card circuits configuration -->
      <el-row v-if="isCardPayment" :gutter="20">
        <el-col :lg="8" :sm="24">
          <card-circuits v-model="form.card_circuits" />
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters, mapState } from 'vuex'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import { isEmptyArray, isEmptyObject } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import { getTypesLabels } from '../helpers'
import CardCircuits from './cardCircuits'

function makeHandleableBody(payload, form) {
  const cleanedPayload = pick(payload, Object.keys(form))

  // replace empty objects, emtpy arrays and empty strings with null
  return Object.keys(cleanedPayload).reduce((result, key) => {
    const isEmpty =
      isEmptyObject(cleanedPayload[key]) ||
      isEmptyArray(cleanedPayload[key]) ||
      isEmptyString(cleanedPayload[key])
    result[key] = isEmpty ? null : cleanedPayload[key]
    return result
  }, {})
}

export default {
  components: {
    CardCircuits
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      valid: false,
      orderIndexError: false,
      form: this.genInitialData(),
      typesLabels: getTypesLabels(this),
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.payment_options.edit.form.rules.name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        fa_account_number: [
          {
            required: true,
            message: this.$t(
              'pages.payment_options.edit.form.rules.fa_account_number.required'
            ),
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 20
            }),
            trigger: 'blur'
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          }
        ],
        order_index: [{ validator: this.validateOrderIndex, trigger: 'blur' }]
      },
      payload: {}
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies'
    }),
    ...mapState({
      indexesList: (state) => state.PaymentOptions.indexesList || []
    }),
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    currentDeprecatedType() {
      return (
        !this.isNew &&
        this.form.type &&
        this.deprecatedTypes.find((type) => type.value === this.form.type)
      )
    },
    paymentOptions() {
      const deprecated = this.currentDeprecatedType
      return deprecated
        ? this.allowedTypes.concat(deprecated)
        : this.allowedTypes
    },
    allowedTypes() {
      return [
        {
          value: 'cash',
          label: this.typesLabels.cash
        },
        {
          value: 'invoice',
          flag: 'invoices',
          label: this.typesLabels.invoice
        },
        {
          value: 'card',
          label: this.typesLabels.card
        },
        {
          value: 'gift_card',
          label: this.typesLabels.gift_card
        },
        {
          value: 'undefined',
          label: this.typesLabels.undefined
        }
      ].filter((type) => (type.flag ? this.$isFeatureEnabled(type.flag) : true))
    },
    deprecatedTypes() {
      return [
        {
          value: 'card_opi',
          label: this.typesLabels.card_opi
        },
        {
          value: 'card_concardis',
          label: this.typesLabels.card_conc
        },
        {
          value: 'card_adyen',
          label: this.typesLabels.card_adye
        },
        {
          value: 'card_tim',
          label: this.typesLabels.card_tim
        },
        {
          value: 'sumup',
          label: this.typesLabels.sumup
        }
      ]
    },
    isDatevEnabled() {
      return this.$isFeatureEnabled('datev')
    },
    isDatevStrictMode() {
      const strictMode = safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        ['datev', 'strict_mode']
      )
      return typeof strictMode === 'boolean' ? strictMode : true
    },
    isCardPayment() {
      return this.form.type === 'card'
    },
    isCardCircuitsEnabled() {
      //Don't show card payment if automatic card_circuits is enabled
      return !!this.form.card_circuits?.length
    },
    showCurrencySelector() {
      return this.currencies.length > 1
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetForm()
      if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.$route.params.id)
    },
    'form.order_index': function () {
      if (this.orderIndexError) this.orderIndexError = false
    },
    'form.type': function () {
      //summable set to 'true' only for Cash option creation
      if (this.isNew) this.form.summable = this.form.type === 'cash'
    },
    'form.card_circuits': function () {
      //set 'active' state to false (don't show card option in the POS) if automatic card_circuits selection is enabled
      this.form.active = !this.isCardCircuitsEnabled
    }
  },
  async mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
    this.form.order_index = this.getFirstAvailableIndex()
  },
  methods: {
    genInitialData() {
      return {
        name: null,
        fa_account_number: null,
        discrepancy_account: null,
        type: null,
        currency: this.getDefaultCurrency(),
        order_index: 0,
        active: true,
        summable: false,
        card_circuits: null,
        price_range: {
          min_amount: null,
          max_amount: null
        }
      }
    },
    async fetch(id) {
      try {
        const inst = th.paymentOptions()

        this.loading = true

        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.payment_option.singular')
          })
        })
      }
    },
    handleItem(item) {
      if (!item.price_range) {
        item.price_range = {
          min_amount: null,
          max_amount: null
        }
      }
      this.payload = item
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
    },
    submitForm() {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })
        }

        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetData() {
      this.form = this.genInitialData()
    },
    getDefaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },
    async alter() {
      const payload = {
        ...this.payload,
        ...this.form
      }

      const shouldWarnOnDatevChange =
        !this.isNew &&
        this.isDatevEnabled &&
        this.isDatevStrictMode === false &&
        this.payload.fa_account_number !== this.form.fa_account_number

      if (shouldWarnOnDatevChange) {
        const objectName = this.$t(
          'pages.payment_options.edit.form.properties.fa_account_number.label'
        )
        try {
          await this.$confirm(
            this.$t('common.datev.related_change.warning.message', {
              objectName
            }),
            this.$t('common.titles.warning'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.ok'),
              cancelButtonText: this.$t('common.interactions.buttons.cancel'),
              type: 'warning'
            }
          )
        } catch (err) {
          // no-op
          return
        }
      }

      try {
        const inst = th.paymentOptions()
        this.loading = true

        const { data = {} } = await inst.put(
          payload.id,
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.payment_option.singular')
          })
        })
        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.checkIndexOrderError(err)
        this.loading = false
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.payment_option.singular')
          })
        })
      }
    },
    async create() {
      const payload = {
        ...this.form
      }

      try {
        const inst = th.paymentOptions()
        this.loading = true

        // we will handle timezone as configuration, TODO: implement
        const { data = {} } = await inst.create({
          ...makeHandleableBody(payload, this.form)
        })

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t('common.resource.payment_option.singular')
          })
        })
        this.$emit('handled-item')
        this.$emit('new-item')
      } catch (err) {
        this.checkIndexOrderError(err)
        this.loading = false
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.payment_option.singular')
          })
        })
      }
    },
    checkIndexOrderError(err) {
      const errorMsg = safeGet(err, 'properties.error.response.data.msg') || ''
      // duplicate key value violates unique constraint \"order_index_unique\"
      if (errorMsg.includes('order_index_unique')) {
        this.orderIndexError = true
        this.$refs.form.validate()
      }
    },
    getFirstAvailableIndex() {
      // DAS-1793 first index will be 1, instead of the historical 0
      let lastIndex = 1
      while (this.indexesList.includes(lastIndex)) {
        lastIndex++
      }
      return lastIndex
    },
    validateOrderIndex(rule, value, callback) {
      if (this.orderIndexError) {
        callback(
          new Error(
            this.$t('pages.payment_options.error.order_index.message', {
              order_index: this.form.order_index
            })
          )
        )
      } else {
        callback()
      }
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  height: 100px;
  overflow: auto;
}

.form-column {
  margin: 0;
}

@media (min-width: 1300px) {
  .actions {
    display: flex;
    justify-content: flex-end;
    justify-items: flex-end;
    align-content: center;
    align-items: center;
  }
}

@media (max-width: 1299px) {
  .actions {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 0;
  }

  .actions :deep(.el-button) {
    margin-top: 10px;
    margin-left: 0px;
  }
}
</style>
